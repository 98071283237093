<template>
    <div class="p-grid dashboard">
        <div class="p-col-6 p-md-3" v-for="(item, index) in todaysTickets" :key="'product' + index">
            <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                <div class="p-d-flex p-ai-center muted-text">
                    <h6 class="p-m-0">{{ $t('products.' + item.product) }}</h6>
                </div>
                <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                    <div class="p-d-flex p-flex-column">
                        <span class="p-mb-1 fs-xlarge">{{ formatCurrency(item.amount) + ' ' + $t('general.currency') }}</span>
                        <span class="overview-status p-p-1 teal-bgcolor fs-small">{{ item.ticketsNo + ' ' + $t('menu.tickets') }} </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-6">
            <div class="card widget-traffic height-100">
                <div class="card-header">
                    <h5>{{ $t('dashboard.todayIncomes') }}</h5>
                </div>
                <div class="p-d-flex p-grid">
                    <div class="p-col-12 p-md-6 left p-d-flex p-flex-column p-jc-evenly">
                        <div class="total p-d-flex p-flex-column">
                            <span class="title p-mb-2">{{ $t('general.total') }}</span>
                            <span class="value p-mb-5">{{ formatCurrency(totalAmount) + ' ' + $t('general.currency') }}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6 p-d-flex p-jc-center">
                        <Chart ref="pie" type="pie" :data="pieDataAmounts" :options="pieOptions" :height="350"></Chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="card widget-traffic height-100">
                <div class="card-header">
                    <h5>{{ $t('dashboard.todayTickets') }}</h5>
                </div>
                <div class="p-d-flex p-grid">
                    <div class="p-col-12 p-md-6 left p-d-flex p-flex-column p-jc-evenly">
                        <div class="total p-d-flex p-flex-column">
                            <span class="title p-mb-2">{{ $t('general.total') }}</span>
                            <span class="value p-mb-5">{{ totalTickets }}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6 p-d-flex p-jc-center">
                        <Chart ref="doughnut" type="doughnut" :data="pieDataTickets" :options="pieOptions" :height="350"></Chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../service/BoReportsApi';

export default {
    data() {
        return {
            BoReportsApi: new BoReportsApi(),
            todaysTickets: [],
            pieDataTickets: null,
            pieDataAmounts: null,
            pieOptions: null,
            totalAmount: 0,
            totalTickets: 0,
        };
    },
    mounted() {
        this.getDashboardData();
    },
    methods: {
        getDashboardData() {
            this.BoReportsApi.getDashboardData()
                .then((response) => {
                    if (this.notNullOrEmptyObject(response.data.SportBetsDetails)) {
                        var sportData = JSON.parse(response.data.SportBetsDetails);
                        if (this.notNullOrEmptyField(sportData.TodayTickets.No)) {
                            this.addTodaysTicketsItem('prematch', sportData.TodayTickets.No, sportData.TodayTickets.Amount);
                        } else {
                            this.addTodaysTicketsItem('prematch', 0, 0);
                        }
                    }
                    if (this.notNullOrEmptyObject(response.data.LiveDetails)) {
                        var liveData = JSON.parse(response.data.LiveDetails);
                        if (this.notNullOrEmptyField(liveData.TodayTickets.No)) {
                            this.addTodaysTicketsItem('live', liveData.TodayTickets.No, liveData.TodayTickets.Amount);
                        } else {
                            this.addTodaysTicketsItem('live', 0, 0);
                        }
                    }
                    if (this.notNullOrEmptyObject(response.data.LottoDetails)) {
                        var lottoData = JSON.parse(response.data.LottoDetails);
                        if (this.notNullOrEmptyField(lottoData.No)) {
                            this.addTodaysTicketsItem('lotto', lottoData.No, lottoData.Amount);
                        } else {
                            this.addTodaysTicketsItem('lotto', 0, 0);
                        }
                    }
                    /*if (this.notNullOrEmptyObject(response.data.NsoftDetails)) {
                        var nsoftData = JSON.parse(response.data.NsoftDetails);
                        if (this.notNullOrEmptyField(nsoftData.No)) {
                            this.addTodaysTicketsItem('nsoft', nsoftData.No, nsoftData.Amount);
                        } else {
                            this.addTodaysTicketsItem('nsoft', 0, 0);
                        }
                    }*/
                    if (this.notNullOrEmptyObject(response.data.KenoDetails)) {
                        var kenoData = JSON.parse(response.data.KenoDetails);
                        if (this.notNullOrEmptyField(kenoData.No)) {
                            this.addTodaysTicketsItem('keno', kenoData.No, kenoData.Amount);
                        } else {
                            this.addTodaysTicketsItem('keno', 0, 0);
                        }
                    }
                    if (this.notNullOrEmptyObject(response.data.BallSixDetails)) {
                        var ballsixData = JSON.parse(response.data.BallSixDetails);
                        if (this.notNullOrEmptyField(ballsixData.No)) {
                            this.addTodaysTicketsItem('ballsix', ballsixData.No, ballsixData.Amount);
                        } else {
                            this.addTodaysTicketsItem('ballsix', 0, 0);
                        }
                    }

                    if (this.notNullOrEmptyObject(response.data.RouletteDetails)) {
                        var rouletteData = JSON.parse(response.data.RouletteDetails);
                        if (this.notNullOrEmptyField(rouletteData.No)) {
                            this.addTodaysTicketsItem('roulette', rouletteData.No, rouletteData.Amount);
                        } else {
                            this.addTodaysTicketsItem('roulette', 0, 0);
                        }
                    }
                    if (this.notNullOrEmptyObject(response.data.RocketDetails)) {
                        var rocketData = JSON.parse(response.data.RocketDetails);
                        if (this.notNullOrEmptyField(rocketData.No)) {
                            this.addTodaysTicketsItem('rocket', rocketData.No, rocketData.Amount);
                        } else {
                            this.addTodaysTicketsItem('rocket', 0, 0);
                        }
                    }

                    this.pieDataAmounts = this.getPieDataAmounts();
                    this.pieDataTickets = this.getPieDataTickets();
                    this.pieOptions = this.getPieOptions();
                    this.todaysTickets.push({ product: 'total', amount: this.totalAmount, ticketsNo: this.totalTickets });
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        addTodaysTicketsItem(productName, ticketsNo, amount) {
            this.todaysTickets.push({ product: productName, amount: amount, ticketsNo: ticketsNo });
            this.totalAmount += amount;
            this.totalTickets += ticketsNo;
        },
        getPieDataAmounts() {
            const { limeColor, blueColor, tealColor, deeporangeColor, lightgreenColor, amberColor, cyanColor } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            return {
                labels: this.todaysTickets.map((i) => this.$t('products.' + i.product)),
                datasets: [
                    {
                        data: this.todaysTickets.map((i) => i.amount),
                        backgroundColor: [blueColor, tealColor, limeColor, deeporangeColor, lightgreenColor, amberColor, cyanColor],
                        borderColor,
                    },
                ],
            };
        },
        getPieDataTickets() {
            const { limeColor, blueColor, tealColor, deeporangeColor, lightgreenColor, amberColor, cyanColor } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            return {
                labels: this.todaysTickets.map((i) => this.$t('products.' + i.product)),
                datasets: [
                    {
                        data: this.todaysTickets.map((i) => i.ticketsNo),
                        backgroundColor: [blueColor, tealColor, limeColor, deeporangeColor, lightgreenColor, amberColor, cyanColor],
                        borderColor,
                    },
                ],
            };
        },
        getPieOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                responsive: true,
                aspectRatio: 1,
                legend: {
                    position: 'top',
                    labels: {
                        fontFamily,
                        fontColor: textColor,
                    },
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
